form.styled {
  .button,
  button,
  input[type="submit"],
  input[type="reset"],
  input[type="button"] {
    display: inline-block;
    height: 38px;
    padding: 0 30px;
    color: #555;
    text-align: center;
    font-size: 11px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: .1rem;
    text-transform: uppercase;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border-radius: 4px;
    border: 1px solid #bbb;
    cursor: pointer;
    box-sizing: border-box; }
  .button:hover,
  button:hover,
  input[type="submit"]:hover,
  input[type="reset"]:hover,
  input[type="button"]:hover,
  .button:focus,
  button:focus,
  input[type="submit"]:focus,
  input[type="reset"]:focus,
  input[type="button"]:focus {
    color: #333;
    border-color: #888;
    outline: 0; }
  .button.button-primary,
  button.button-primary,
  input[type="submit"].button-primary,
  input[type="reset"].button-primary,
  input[type="button"].button-primary {
    color: #FFF;
    background-color: #33C3F0;
    border-color: #33C3F0; }
  .button.button-primary:hover,
  button.button-primary:hover,
  input[type="submit"].button-primary:hover,
  input[type="reset"].button-primary:hover,
  input[type="button"].button-primary:hover,
  .button.button-primary:focus,
  button.button-primary:focus,
  input[type="submit"].button-primary:focus,
  input[type="reset"].button-primary:focus,
  input[type="button"].button-primary:focus {
    color: #FFF;
    background-color: #1EAEDB;
  }
  

  input[type="email"],
  input[type="number"],
  input[type="search"],
  input[type="text"],
  input[type="tel"],
  input[type="url"],
  input[type="password"],
  textarea,
  select {
    height: 38px;
    padding: 6px 10px; /* The 6px vertically centers text on FF, ignored by Webkit */
    background-color: #fff;
    border: 1px solid #D1D1D1;
    border-radius: 4px;
    box-shadow: none;
    box-sizing: border-box; }
  /* Removes awkward default styles on some inputs for iOS */
  input[type="email"],
  input[type="number"],
  input[type="search"],
  input[type="text"],
  input[type="tel"],
  input[type="url"],
  input[type="password"],
  textarea {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none; }
  textarea {
    min-height: 65px;
    padding-top: 6px;
    padding-bottom: 6px; }
  input[type="email"]:focus,
  input[type="number"]:focus,
  input[type="search"]:focus,
  input[type="text"]:focus,
  input[type="tel"]:focus,
  input[type="url"]:focus,
  input[type="password"]:focus,
  textarea:focus,
  select:focus {
    border: 1px solid #33C3F0;
    outline: 0; }
  label,
  legend {
    display: block;
    margin-bottom: .5rem;
    font-weight: 600; }
  fieldset {
    padding: 0;
    border-width: 0; }
  input[type="checkbox"],
  input[type="radio"] {
    display: inline; }
  label > .label-body {
    display: inline-block;
    margin-left: .5rem;
    font-weight: normal; }

  button,
  .button {
    margin-bottom: 1rem; }
  input,
  textarea,
  select,
  fieldset {
    margin-bottom: 1.5rem; }
  pre,
  blockquote,
  dl,
  figure,
  table,
  p,
  ul,
  ol,
  form {
    margin-bottom: 2.5rem; }
}

.xo_text {
  width: 250px;
}
