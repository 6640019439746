@use "forms";
@use "images";

$grey: #ddd;
$medium_grey: #aaa;
$width: 630px; // 42em
@mixin rounded($radius: 10px) {
  border-radius: $radius;
  -moz-border-radius-: $radius;
  -webkit-border-radius: $radius;
}

$small-font-size: 13px;
$text-font-size: 15px;
$title-font-size: 22px;

/*****************************************************************************/
/*
/* Common
/*
/*****************************************************************************/

/* Global Reset */

* {
  margin: 0;
  padding: 0;
}

// html, body {
//  height: 100%;
// }

body {
  background-color: #fefefe;
  font: $small-font-size helvetica, arial, clean, sans-serif;
  *font-size: small;
  text-align: center;
}

h1, h2, h3, h4, h5, h6 {
  font-size: $small-font-size * 1.2;
  margin: 1em 0;
}

p {
  margin: 1em 0;
}

a {
  color: #00a;
}

a:hover {
  color: black;
}

a:visited {
  color: #a0a;
}

table {
  font-size: inherit;
  font: 100%;
}

.align-right{
  text-align:right;
}

/*****************************************************************************/
/*
/* Home
/*
/*****************************************************************************/

ul.posts {
  list-style-type: none;
  margin-bottom: 2em;
  text-align: left;
}

ul.posts li {
  line-height: 1.75em;
}

ul.posts li {
  text-indent: -6rem;
  padding-left: 6rem;
}

ul.year-list.posts li {
  text-indent: -3em;
  padding-left: 3em;
}

.attribution{
  font-size: 100%;
  font-style: italic;
}

/*****************************************************************************/
/*
/* Site
/*
/*****************************************************************************/

.site {
  font-size: $text-font-size;
  text-align: justify;
  margin: 3em auto 2em auto;
  width: 87%;
  line-height: 1.5em;
}

/* #### Desktops #### */
@media screen and (min-width: 1024px){
  .site {
    width: $width;
  }
}

.site .slides{
  width: $width;
  height: calc($width * 3 / 4);
}

.title {
  color: #a00;
  font-weight: bold;
  margin-bottom: 1.5em;
}

.title .name{
  font-size: $title-font-size;
}

.site .title a {
  color: #a00;
  text-decoration: none;
}

.site .title a:hover {
  color: black;
}

.site .title a.extra {
  color: $medium_grey;
  text-decoration: none;
  margin-left: 1em;
}

.site .title a.extra:hover {
  color: black;
}

.site .meta {
  color: $medium_grey;
}

.site .footer {
  font-size: 80%;
  color: #666;
  border-top: 4px solid #eee;
  margin-top: 2em;
  overflow: hidden;
}

.site .footer .contact {
  float: left;
  margin-right: 3em;
}

.site .footer .contact a {
  color: #8085C1;
}

// .site .footer .rss {
//  margin-top: 1.1em;
//  margin-right: -.2em;
//  float: right;
// }

// .site .footer .rss img {
//  border: 0;
// }

/*****************************************************************************/
/*
/* Posts
/*
/*****************************************************************************/

#post {

}

/* standard */

#post pre {
  border: 1px solid $grey;
  background-color: #eef;
  padding: 0 .4em;
  margin: 1em 0;
}

#post ul,
#post ol {
  margin-left: 1.35em;
}

.highlight {
  overflow-x: scroll;
}

#post code {
  border: 1px solid #ddd;
  padding: 2px;
  background-color: #eef;
  font-size: 85%;
  @include rounded(4px);
}

#post pre {
  @include rounded(4px);
}

#post pre code {
  border: none;
  padding: 0px;
}

#post blockquote, .site blockquote {
  border-left: .4em solid $grey;
  padding-left: 1em;
  margin: 1em 0;
}

#related {
  margin-top: 2em;
}

#related h2 {
  margin-bottom: 1em;
}

/* Labels */

.small-muted {
  color: $medium_grey;
  font-family: Monaco, "Courier New", monospace;
  font-size: 80%;
}

.label {
  @include rounded(4px);
  font-size: 85%;
  background-color: $grey;
  padding-right: 4px;
  padding-left: 4px;
  padding-bottom: 2px;
  padding-top: 2px;
}

.nowrap {
  white-space: nowrap;
}

.text-left {
  text-align: left;
}

.video-border {
  border: 5px solid $grey;
}

.video-container {
  position: relative;
  padding-bottom: 62.5%;
  // padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
